@import url('https://fonts.googleapis.com/css?family=Work+Sans:300,400,500,600,700,800&display=swap');

.mt-3,
.my-3 {
    margin-top: 3rem !important;
}
.custom_profession label {
    margin: 0;
    padding-right: 4px;
}

.form-row {
    display: flex;
    text-align: left;
}

.col-md-12.custom_text {
    font-size: 16px;
    font-weight: 600;
}

.btn-danger {
    color: #fff;
    background-color: #e24f36;
    border-color: #e24f36;
}

.heading {
    font-weight: 700 !important;
}

.arab-table {
    padding: 30px;
}

.profile-table-padding {
    padding: 0 0 0 5px !important;
}

/*font-family: 'Work Sans', sans-serif;*/

/*font-family: 'Work Sans', sans-serif;*/

* {
    padding: 0;
    margin: 0;
}

body {
    font-family: 'Work Sans', sans-serif;
    color: #333 !important;
}

img {
    max-width: 100%;
}

a:hover {
    text-decoration: none;
}

ul li {
    list-style: none;
}

.profile {
    margin-top: 30px;
    background: #f7f7f7;
    border-radius: 4px;
    overflow: hidden;
}

.profile .exp {
    background: orange;
    padding: 3px 6px;
    border-radius: 8px;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    display: inline-block;
}

.profile .exp img {
    padding-right: 4px;
}
.startFromProvider {
    position: absolute;
    top: 8px;
    background-color: #e74b37;
    width: auto;
    height: 30px;
    border-radius: 0 10px 10px 0;
}
.favoriteIcon {
    position: absolute;
    top: 8px;
    left: 20.5%;
    fill: #e24f36 !important;
    color: #e24f36;
    font-size: 15px !important;
}
.favoriteIconMobile {
    position: absolute;
    top: 8px;
    left: 44.5%;
    fill: #e24f36 !important;
    font-size: 15px !important;
}
.profile .pic {
    width: 80px;
    position: inherit;
    top: 19px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    /* border: 2px solid #ffffff;
    padding: 4px; */
    margin: 0px auto 20px;
}
.profile .price-starting {
    background-color: #e24f36;
    left: 0;
    position: absolute;
    top: 13px;
    font-weight: 500;
    padding: 4px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.profile .rating .stars {
    display: flex;
    align-items: center;
    padding: 5px 0 0 !important;
    width: 100px !important;
    justify-content: space-between;
}

.profile .pic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.providerName {
    height: 90px;
    margin-top: -20px;
}
.providerNameMobile {
    height: auto;
    margin-top: 0px;
}
.featured ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.featured ul li {
    position: relative;
    padding: 0 0 0 10px !important;
}

.featured ul li i {
    position: absolute;
    right: 0px;
    background: #fff;
    border-radius: 50%;
    padding: 4px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: fit-content;
    border: 4px solid;
    font-size: 29px;
}

.featured ul li button {
    padding: 6px 53px 6px 20px;
    border-radius: 25px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
}

.featured ul li:first-child {
    color: #e24f36;
    border-color: white;
}

.featured ul li:nth-child(2) {
    color: #64bc35;
    border-color: white;
}

.secondryDiv {
    margin-bottom: 20px;
}
.secondryDivMobile {
    margin-bottom: 20px;
}
.providerExperience {
    background-color: #ffa400;
    padding: 8px 5px;
    color: #fff;
    border-radius: 10px;
    display: inline-block;
    font-size: 12px;
    margin-left: 5px;
    margin-bottom: 0;
    text-align: center;
}
.recommended-position-mobile {
    position: absolute;
    top: 5px;
    right: 0;
}

.recommended-position {
    position: absolute;
    top: 17px;
    right: 20%;
    display: flex;
}
.provider-address {
    text-align: end;
    bottom: 84px;
    font-size: 13px;
}
.provider-address-mobile {
    bottom: auto;
}
.providerBtn {
    height: 30px;
}
.featured2 ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.featured2 ul li {
    position: relative;
    right: 10px;
}

.featured2 ul li i {
    position: absolute;
    background: #fff;
    border-radius: 50%;
    padding: 1px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: fit-content;
    border: 4px solid;
    font-size: 32px;
}

.font-20 {
    font-size: 20px !important;
    left: 0 !important;
}

.featured2 ul li button {
    padding: 0px 20px 0px 45px;
    border-radius: 25px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    border-width: 0;
}

.recommended-size-mobile {
    padding: 0px 20px 0px 30px !important;
}

.featured2 ul li:first-child {
    color: #e24f36;
    border-color: white;
}

.featured2 ul li:nth-child(2) {
    color: #64bc36;
    border-color: white;
    border-radius: 25px;
}

.rating {
    display: flex;
}
.providerCategories {
    margin-top: 20px;
    color: #e24f36;
    font-weight: 500;
    word-wrap: break-word;
}
.providerCategoriesMobile {
    color: #e24f36;
    font-weight: 500;
    word-wrap: break-word;
}
.providerNationality {
    display: flex;
}
.nationalityText {
    color: #000000;
}
.nationality {
    color: #e74b37;
    margin-left: 10px;
}
.genderText {
    color: #000000;
    margin-left: 50px;
}
.gender {
    color: #e74b37;
    margin-left: 10px;
}
.providerLanuages {
    display: flex;
}
.languagesText {
    color: #000000;
}
.languages {
    color: #e74b37;
    margin-left: 10px;
}
.specialityDiv {
    display: -webkit-box;
    height: 80px;
}
.providerSpeciality {
    display: flex;
    /* width: 850px; */
}
.specialityText {
    color: #000000;
}
.speciality {
    color: #ffffff;
    margin-left: 10px;
    background-color: #e74b37;
    border-radius: 10px;
    padding: 0 5px;
}
.cust_rate {
    display: flex;
    padding: 5px 0 0 !important;
}

.cust_rate li {
    padding-left: 10px;
    font-weight: 600;
}

.profile .overall {
    padding: 20px 0 0;
    margin: 0;
    width: 100%;
}

.pic .connect {
    margin: 0;
    padding: 0;
}

.profile .connect {
    padding: 5px 0 15px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile .connect li a {
    color: #fff;
    padding: 4px 11px;
    transition: all 0.4s ease-in-out;
    margin-right: 10px;
}

.profile ul li b {
    font-size: 22px;
    padding-right: 6px;
}

.profile .connect li a:hover {
    background: #fff;
    color: #e24f36;
    cursor: pointer;
}

.profile .overall li {
    padding-bottom: 6px;
    font-size: 18px;
    letter-spacing: 1px;
}

.featured {
    padding: 20px 20px 0 0;
    text-align: right;
}

.details > h5 {
    margin-bottom: 20px;
    position: relative;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
}

.details > h5:after {
    content: '';
    background: #333;
    position: absolute;
    width: 100px;
    height: 2px;
    bottom: -5px;
    left: 0;
}

.services .nav {
    margin-top: 30px;
    border: 0;
}

.services .nav-tabs .nav-link {
    background: #e24f36;
    border: 0;
    border-radius: 0px;
    letter-spacing: 2px;
    text-align: center;
    padding: 8px 10px;
    color: #fff;
    font-size: 18px;
    width: 33.15%;
    min-width: 33.15%;
    border-bottom: 1px solid #e6e6e6;
    margin: 0 1px;
    opacity: 1;
    text-transform: capitalize;
    font-weight: 500;
}

.services .nav-tabs .nav-item.show .nav-link,
.services .nav-tabs .nav-link.active {
    color: #333;
    background-color: #f7f7f7;
}

.services .tab-content {
    border: 1px solid #f1f1f3;
    padding: 20px;
    background: #f7f7f7;
}

.accordion {
    margin-bottom: 30px;
}

.accordion .card {
    border: 0;
    margin-bottom: 10px;
}

.accordion .card-header {
    padding: 0;
    margin-bottom: 0 !important;
    border: 0;
    background: transparent;
}

.accordion .card-header button {
    color: #333;
    border: 0px;
    background: #fff;
    width: 100%;
    text-align: left;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.accordion .card-header button:hover,
.accordion .card-header button:focus {
    text-decoration: none;
}

.accordion .card-header button[aria-expanded='true'] {
    background: #e24f36;
    color: #fff;
}

.provider_card {
    background: #323a45;
    text-align: center;
    color: #fff;
    padding: 20px;
    position: relative;
    height: 100%;
}

.provider_card .rating .stars {
    padding: 0 0 0 !important;
    margin: 0 10px 0;
}

.provider_card h5 {
    margin-bottom: 0;
    color: #c8d4de;
    font-weight: 400;
    font-size: 18px;
}

.portfolio {
    margin-bottom: 30px;
}

.provider_card p {
    color: #c8d4de;
    line-height: 20px;
    margin: 10px 0px;
    font-size: 15px;
}

.provider_card .rating {
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
}

.provider_card h6 {
    font-size: 18px;
    margin-bottom: 0px;
    color: white;
}
.logintxt {
    justify-content: left !important;
}
.loginBtn {
    box-sizing: border-box;
    position: relative;
    /* width: 13em;  - apply for fixed size */
    margin: 0.2em;
    padding: 0 15px 0 46px;
    border: none;
    text-align: left;
    line-height: 34px;
    white-space: nowrap;
    border-radius: 0.2em;
    font-size: 16px;
    color: #fff;
}
.loginBtn:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 34px;
    height: 100%;
}
.loginBtn:focus {
    outline: none;
}
.loginBtn:active {
    box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* Facebook */
.loginBtn--facebook {
    background-color: #4c69ba;
    background-image: linear-gradient(#4c69ba, #3b55a0);
    text-shadow: 0 -1px 0 #354c8c;
}
.loginBtn--facebook:before {
    border-right: #364e92 1px solid;
}
.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
    background-color: #5b7bd5;
    background-image: linear-gradient(#5b7bd5, #4864b1);
}

/* Google */
.loginBtn--google {
    background: #dd4b39;
}
.loginBtn--google:before {
    border-right: #bb3f30 1px solid;
}
.loginBtn--google:hover,
.loginBtn--google:focus {
    background: #e74b37;
}
.signUp-heading {
    color: #ffffff;
    font-size: 20px;
    margin: 1px 15px;
    padding: 15px;
    text-align: left;
    text-transform: capitalize;
    font-weight: 500;
}
.like_icon {
    background: orange;
    padding: 3px 8px 5px;
    border-radius: 4px;
}

.fa .fa-instagram {
    color: transparent;
    background: radial-gradient(
        circle at 30% 107%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
    ) !important;
    background: -webkit-radial-gradient(
        circle at 30% 107%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
    ) !important;
    background-clip: text;
    -webkit-background-clip: text;
}

.kep-login-facebook .fa {
    margin-right: none !important ;
}

.kep-login-facebook {
    font-family: Helvetica, sans-serif;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    color: #fff;
    width: 40px !important;
    padding: 5px 1px !important;
    border-radius: 60px !important;
    cursor: pointer;
    display: inline-block;
    transition: background-color 0.3s, border-color 0.3s;
    background-color: #4c69ba;
}

i.fa.fa-facebook {
    font-size: 18px;
}
/* .kep-login-facebook svg,
    .google-btn svg , .Insta-btn svg{
      display: none; }
    .kep-login-facebook div,
    .google-btn div, .Insta-btn div {
      padding: 0 !important;
      margin: 0 !important; }
    .kep-login-facebook span,
    .google-btn span, .Insta-btn span  {
      padding: 0 !important;
      width: 100% !important; }
   */
.google-btn {
    /* background-color: #dd5144 !important; */
    color: #fff !important;
    border: 0px;
    border-radius: 60px !important;
    font-weight: 600;
    margin-right: none !important;
    letter-spacing: 0px;
    font-size: 10px !important;
}
.fa-facebook-f:before,
.fa-facebook:before {
    content: '\f39e';
    margin-right: -5px;
}
.Insta-btn {
    background: linear-gradient(
        to right bottom,
        rgb(252, 98, 98),
        rgb(231, 125, 49)
    );
    color: #fff !important;
    border: 0px;
    font-weight: 600;
    letter-spacing: 0px;
    font-size: 10px !important;
}

button.packageButton.MuiButtonBase-root-11293.MuiTab-root-11485.NavPills-pills-11523.MuiTab-textColorInherit-11487.MuiTab-selected-11490.NavPills-primary-11530 {
    border-radius: 8px;
}

.tags {
    display: flex;
    align-items: center;
    margin: 0px 0 0;
    flex-wrap: wrap;
}

.tags h5 {
    margin: 0 10px 0 0;
    text-transform: uppercase;
    font-weight: 500;
}

.tags li span {
    background: #e24f36;
    padding: 10px 20px;
    border-radius: 8px;
    color: #fff;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
}

.tags li {
    padding: 0 3px 20px;
}

.stars .fill {
    color: orange;
}

.cust_tabz .nav-tabs div {
    width: 100%;
}

.accordion > div {
    border: 0;
    margin-bottom: 10px;
}

.expansionSummary {
    width: 100%;
}
.expansionSummary {
    /* margin: 12px 0; */
    /* display: flex; */
    /* flex-grow: 1; */
    /* transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
    width: 100%;
}
/**/
.price_rate {
    display: inline-grid;
}
.experience {
    background: #fff;
    color: #e24f36;
    display: inline;
    padding: 4px 10px;
    border-radius: 30px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    font-weight: 500;
}

.fav i {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
}

.packageButton {
    border-radius: 0px;
}

.profiledata li span:first-child {
    width: 20%;
    /* display: inline-block; */
}

.profiledata li span:last-child {
    width: 80%;
    /* display: inline-block; */
}

.profiledata li {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    padding: 0px 0px;
    display: flex;
    align-items: center;
}

.popup {
    position: relative;
    padding-left: 30px;
    margin-bottom: 8px;
}

.popup .material-icons {
    position: absolute;
    left: 0;
    top: 2px;
}
.demo:hover {
    background: #e24f36e0;
}

.demo:hover img {
    filter: brightness(100);
}

.demo:hover span {
    color: #fff;
}

.demo {
    transition: all 0.4s ease-in-out;
}
.demo1:hover {
    background: #e24f36e0;
}
.demo1:hover span {
    color: #fff;
}

.demo1 {
    transition: all 0.4s ease-in-out;
}

.category-image {
    width: '300px';
    height: '300px';
}

.google-btn {
    padding: 5px 10px !important;
    justify-content: center !important;
}

.google-btn > div {
    margin: 0 !important;
    padding: 0 !important;
}

.google-btn span {
    display: none;
}

.social-link {
    padding-right: 8px;
}

span.login-header {
    color: white;
    margin: 0px 20px;
    font-size: 14px;
    font-weight: 400;
}
span.login-header2 {
    color: white;
    font-size: 14px;
    font-weight: 400;
}
.dropbox {
    border-color: rgba(226, 79, 54, 0.48) !important;
    background: #e24f3626;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    height: auto !important;
    padding: 100px 20px;
    text-align: center;
}

.dropbox .inner p {
    line-height: normal;
    text-align: center;
    font-size: 14px;
    margin: 0px;
    color: #333;
}

.text-left {
    border-bottom: 0 !important;
}
.addNewButton {
    border-radius: 5px !important;
    line-height: normal !important;
    width: 100px !important;
    /* font-size: 15px !important; */
    height: 40px;
    padding: -5px !important;
}

.w-180 {
    width: 180px !important;
}

.dropbox svg {
    font-size: 90px;
    color: #e24f36;
}

.genere-btn {
    border: 2px solid #242b33;
    margin: 0;
    padding: 10px 50px 10px 0px;
    text-align: center;
    color: #242b33;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: 15px;
    position: relative;
}

.genere-button {
    border: 2px solid #242b33;
    margin: 0;
    padding: 10px;
    text-align: center;
    color: #242b33;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: 15px;
    position: relative;
}

/* .genere-btn:hover, */
.genere-btn:focus,
.genere-btn:active,
.genere-btn.active {
    background: #242b33;
    color: #fff;
}

.genere-button:focus,
.genere-button:active,
.genere-button.active {
    background: #242b33;
    color: #fff;
}

.br-5 {
    border-radius: 5px;
}

.addbtn {
    background: #f6e0de;
    color: #e24f36;
    border-color: #f6e0de;
    font-weight: 600;
    text-transform: uppercase;
}

.addbtn:hover,
.addbtn:focus,
.addbtn:active,
.addbtn.active {
    color: #fafafa;
    background: #e24f36;
    border-color: #e24f36;
}

.genere-btn:hover .cross {
    display: block;
}

.cross {
    position: absolute;
    top: 7px;
    right: 20px;
    background: #e24f36;
    border: 1px solid #fff;
    border-radius: 50%;
    color: #fff;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    /* display: none; */
}

.cross.active {
    background: #e24f36;
    border-color: #242b33;
    color: #242b33;
}

.cross2 {
    position: absolute;
    top: 10px;
    right: 20px;
    background: #e24f36;
    border: 1px solid #fff;
    border-radius: 50%;
    color: #fff;
    padding: 0px 10px;
    font-size: 12px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    /* display: none; */
}

.cross2-mobile {
    position: absolute;
    top: 7px;
    right: 10px;
    background: #e24f36;
    border: 1px solid #fff;
    border-radius: 50%;
    color: #fff;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    /* display: none; */
}

.cross2.active {
    background: #e24f36;
    border-color: #242b33;
    color: #242b33;
}

.cross2-mobile.active {
    background: #e24f36;
    border-color: #242b33;
    color: #242b33;
}

h2.serviceText {
    font-weight: 700 !important;
    font-size: 35px;
}
/*responsive*/
@media (min-width: 991.98px) and (max-width: 1199.98px) {
    .featured ul {
        flex-direction: column;
        align-items: flex-end;
        margin: 0;
    }

    .featured ul li {
        margin-bottom: 20px;
    }

    .services .nav-tabs .nav-link {
        width: 33.1%;
    }
}

@media (max-width: 991.98px) {
    .services .nav-tabs .nav-link {
        width: 33%;
    }
    .featured {
        text-align: left;
    }

    .featured ul {
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .featured ul li {
        padding: 0 !important;
        margin-right: 10px;
    }

    .provider_card .rating {
        flex-wrap: wrap;
    }

    .like_icon {
        margin-top: 5px;
    }

    .tags li {
        padding-bottom: 10px;
    }

    .tags li:first-child {
        width: 100%;
    }

    .tags li span {
        padding: 7px 14px;
        font-size: 11px;
        display: block;
    }

    .tags {
        padding: 0 15px 15px 0;
    }
    .profiledata li span:first-child {
        width: 35%;
    }
}

@media (max-width: 767.98px) {
    .services .nav-tabs .nav-link {
        width: 32.9%;
        letter-spacing: 0px;
    }

    .featured ul li button {
        padding: 6px 40px 6px 16px;
        font-size: 11px;
    }

    .featured ul li i {
        font-size: 20px;
    }
    .profiledata li span:first-child {
        width: 45%;
    }
}

@media (min-width: 576.98px) and (max-width: 767.98px) {
    .container {
        max-width: 720px;
    }

    .featured ul li {
        margin-bottom: 10px;
    }
}

@media (max-width: 575.98px) {
    .services .nav-tabs .nav-link {
        width: 32.6%;
        padding: 8px 4px;
        font-size: 12px;
    }

    .port_pic {
        margin-bottom: 20px;
    }

    .profile .overall {
        padding: 20px;
        padding-bottom: 0px;
    }

    .featured {
        padding: 0 0 0 15px;
    }

    .featured ul li {
        margin-top: 15px;
        margin-right: 3px;
    }

    .rating {
        flex-wrap: wrap;
    }

    .profile .overall li {
        font-size: 15px;
    }

    .profile .rating .stars {
        padding-right: 7px !important;
    }

    .tags {
        padding-left: 15px;
    }

    .profiledata {
        padding: 15px;
    }
    .popup {
        font-size: 14px;
    }
}

@media (max-width: 360px) {
    .cust_rate li {
        padding-left: 0;
        padding-right: 10px;
    }

    .profiledata li span:first-child {
        width: 50%;
    }
}
.sendQueryTitle {
    display: flex;
    background-color: #e24f36;
    padding: 15px;
}
.sendQueryText {
    font-size: 30px;
    width: 95%;
    font-family: 'Font Awesome 5 Free';
    color: #ffffffff;
}
.sendQueryClose {
    background-color: #fff;
    color: #e24f36;
    border-radius: 12px;
}
.passwordIcon {
    padding: 0 !important;
    font-size: 1px !important;
    border: none !important;
    color: #7e8890 !important;
}
.portfolioVideo {
    margin-top: 18%;
    height: auto;
    display: flex;
    overflow-wrap: break-word;
}
.walletTransaction {
    display: block !important;
}
.addWalletBtn {
    margin-bottom: 20px;
    margin-left: 65%;
}
.walletTable {
    margin-left: -10px;
}
.updateCancelImage {
    margin-left: 35%;
    margin-top: -112px;
}
.pointerCursor:hover {
    cursor: pointer;
}
.appIconStyle {
    margin-left: 15px;
    width: 250px;
    height: 100px;
}
.playIconStyle {
    margin-top: -9px;
    width: 250px;
    height: 120px;
}
.appIconStyleMobile {
    margin-left: 15px;
    width: 150px;
    height: 58px;
}
.playIconStyleMobile {
    margin-top: -5px;
    width: 150px;
    height: 70px;
}
