@import url("https://fonts.googleapis.com/css?family=Work+Sans:300,400,500,600,700");
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  font-size: 1rem; }

body, h1, h2, h3, h4, h5, h6 {
  font-family: 'Work Sans', sans-serif;
  font-weight: 300;
  line-height: 1.5em; }

h1, h2, h3, h4, h5, h6 {
  color: inherit; }

h1, h2, h3 {
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px; }

h4, h5, h6 {
  margin-top: 10px;
  margin-bottom: 10px; }

h1 {
  font-size: 3.3125rem;
  line-height: 1.15em; }

h2 {
  font-size: 2.25rem;
  line-height: 1.5em; }

h3 {
  font-size: 1.5625rem;
  line-height: 1.4em; }

h4 {
  font-size: 1.125rem;
  line-height: 1.5em;
  font-weight: 400; }

h5 {
  font-size: 1.0625rem;
  line-height: 1.55em; }

h6 {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 500; }

p {
  font-size: 14px;
  margin: 0 0 10px; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

body {
  background-color: #E5E5E5;
  color: #3C4858;
  margin: 0;
  font-size: 1rem;
  text-align: left; }

.align-center {
  align-items: center; }

legend {
  border-bottom: 0; }

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box; }
  *:focus {
    outline: 0; }

a {
  color: #9c27b0;
  text-decoration: none;
  background-color: transparent; }
  a:hover, a:focus {
    color: #89229b;
    text-decoration: none; }

label {
  font-size: 16px;
  line-height: 1.42857;
  color: #323a45;
  font-weight: 400; }

small {
  font-size: 75%;
  color: #777;
  font-weight: 400; }

img {
  vertical-align: middle;
  border-style: none; }

form {
  margin-bottom: 1.125rem; }

.add-check-padding {
  color: #e24f36 !important; }

.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-target {
  position: relative;
  direction: ltr; }

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1; }

/* Wrapper for all connect elements.
*/
.noUi-connects {
  overflow: hidden;
  z-index: 0; }

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0; }

/* Offset direction
*/
html:not([dir='rtl']) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0; }

/* Give origins 0 height/width so they don't interfere with clicking the
* connect elements.
*/
.noUi-vertical .noUi-origin {
  width: 0; }

.noUi-horizontal .noUi-origin {
  height: 0; }

.noUi-handle {
  position: absolute; }

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s; }

.noUi-state-drag * {
  cursor: inherit !important; }

/* Slider size and handle placement;
*/
.noUi-horizontal {
  height: 2px;
  margin: 15px 0; }

.noUi-horizontal .noUi-handle {
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  left: -10px;
  top: -6px;
  cursor: pointer;
  border-radius: 100%;
  transition: all 0.2s ease-out;
  border: 1px solid #9c27b0;
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); }

.noUi-vertical {
  width: 2px;
  margin: 0 15px;
  height: 100%; }

.noUi-vertical .noUi-handle {
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  left: -6px;
  top: -10px;
  cursor: pointer;
  border-radius: 100%;
  transition: all 0.2s ease-out;
  border: 1px solid #9c27b0;
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); }

html:not([dir='rtl']) .noUi-horizontal .noUi-handle {
  right: -4px;
  left: auto; }

/* Styling;
* Giving the connect element a border radius causes issues with using transform: scale
*/
.noUi-target {
  background-color: #c8c8c8;
  border-radius: 3px; }

.noUi-connects {
  border-radius: 3px; }

.noUi-connect {
  background: #888;
  border-radius: 3px;
  -webkit-transition: background 450ms;
  transition: background 450ms; }

/* Handles and cursors;
*/
.noUi-draggable {
  cursor: ew-resize; }

.noUi-vertical .noUi-draggable {
  cursor: ns-resize; }

.noUi-handle {
  border-radius: 3px;
  background: #fff;
  cursor: default;
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
  -webkit-transition: 300ms ease 0s;
  -moz-transition: 300ms ease 0s;
  -ms-transition: 300ms ease 0s;
  -o-transform: 300ms ease 0s;
  transition: 300ms ease 0s; }
  .noUi-handle:focus {
    outline: none; }

.noUi-active {
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb; }

/* Disabled state;
*/
[disabled] .noUi-connect {
  background: #b8b8b8; }

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed; }

/* Base;
*
*/
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-pips {
  position: absolute;
  color: #999; }

/* Values;
*
*/
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center; }

.noUi-value-sub {
  color: #ccc;
  font-size: 10px; }

/* Markings;
*
*/
.noUi-marker {
  position: absolute;
  background: #ccc; }

.noUi-marker-sub {
  background: #aaa; }

.noUi-marker-large {
  background: #aaa; }

/* Horizontal layout;
*
*/
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%; }

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%); }

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%); }

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px; }

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px; }

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px; }

/* Vertical layout;
*
*/
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%; }

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%, 0);
  padding-left: 25px; }

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%); }

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px; }

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px; }

.noUi-marker-vertical.noUi-marker-large {
  width: 15px; }

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap; }

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%; }

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%; }

.noUi-target .noUi-handle {
  border: 1px solid #333; }

.noUi-target.slider-primary .noUi-connect, .noUi-target.slider-primary.noUi-connect {
  background-color: #9c27b0; }

.noUi-target.slider-primary .noUi-handle {
  border-color: #9c27b0; }

.noUi-target.slider-info .noUi-connect, .noUi-target.slider-info.noUi-connect {
  background-color: #00bcd4; }

.noUi-target.slider-info .noUi-handle {
  border-color: #00bcd4; }

.noUi-target.slider-success .noUi-connect, .noUi-target.slider-success.noUi-connect {
  background-color: #4caf50; }

.noUi-target.slider-success .noUi-handle {
  border-color: #4caf50; }

.noUi-target.slider-warning .noUi-connect, .noUi-target.slider-warning.noUi-connect {
  background-color: #ff9800; }

.noUi-target.slider-warning .noUi-handle {
  border-color: #ff9800; }

.noUi-target.slider-danger .noUi-connect, .noUi-target.slider-danger.noUi-connect {
  background-color: #f44336; }

.noUi-target.slider-danger .noUi-handle {
  border-color: #f44336; }

.noUi-target.slider-rose .noUi-connect, .noUi-target.slider-rose.noUi-connect {
  background-color: #e91e63; }

.noUi-target.slider-rose .noUi-handle {
  border-color: #e91e63; }

/*!
* https://github.com/YouCanBookMe/react-datetime
*/
.rdt {
  position: relative; }
  .rdt .rdtPicker {
    -webkit-transition: all 150ms linear;
    -moz-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    -ms-transition: all 150ms linear;
    transition: all 150ms linear;
    margin-top: -20px;
    visibility: hidden;
    display: block;
    opacity: 0; }
  .rdt.rdtOpen .rdtPicker {
    opacity: 1;
    visibility: visible;
    margin-top: 0; }
  .rdt input.form-control {
    border: 0;
    background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2);
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center calc(100% - 1px);
    background-color: rgba(0, 0, 0, 0);
    transition: background 0s ease-out;
    float: none;
    box-shadow: none;
    border-radius: 0;
    font-weight: 400;
    width: 100%;
    height: 36px;
    padding: 7px 0;
    font-size: 14px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.42857;
    display: block;
    width: 100%;
    color: #555; }
  .rdt input.form-control:focus {
    outline: none;
    background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2);
    background-size: 100% 2px, 100% 1px;
    box-shadow: none;
    transition-duration: 0.3s; }

.rdtPicker {
  display: none;
  position: absolute;
  width: 260px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  border-radius: 0.125rem;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  min-width: 160px; }
  .rdtPicker:before {
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    vertical-align: middle;
    content: "";
    top: -5px;
    left: 10px;
    right: auto;
    color: #FFFFFF;
    border-bottom: .4em solid;
    border-right: .4em solid transparent;
    border-left: .4em solid transparent; }
  .rdtPicker:after {
    border-bottom: .4em solid #FFFFFF;
    border-right: .4em solid transparent;
    border-left: .4em solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    top: -5px;
    left: 10px; }

.rdtPicker {
  display: block;
  top: 40px; }

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static; }

.rdtPicker .rdtTimeToggle {
  text-align: center;
  padding: 5px;
  border-radius: 4px; }

.rdtPicker table {
  width: 100%;
  margin: 0;
  border-color: #fff !important;
  border-collapse: collapse; }

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  padding: 1px; }

.rdtPicker td {
  cursor: pointer; }

.rdtDay {
  height: 30px;
  line-height: 33px;
  width: 30px;
  text-align: center;
  padding: 0px;
  border-radius: 50%; }
  .rdtDay.rdtToday.rdtActive, .rdtDay.rdtActive, .rdtDay.rdtActive:hover {
    background-color: #9c27b0 !important;
    color: #fff;
    box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(156, 39, 176, 0.6); }

.rdtDays tr .dow {
  border-bottom: 1px solid #E3E3E3;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: inherit;
  padding-bottom: 5px;
  padding-top: 10px; }

.rdtDays tr .rdtOld,
.rdtDays tr .rdtNew {
  color: #bdbdbd; }

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer; }

.rdtPicker td.rdtToday {
  position: relative; }

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff; }

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker td span.rdtOld {
  color: #999999; }

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none; }

.rdtPicker th.rdtSwitch {
  width: 50px;
  padding: 5px;
  border-radius: 4px; }

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
  border-radius: 50%;
  line-height: 33px; }

.rdtPicker .dow,
.rdtPicker th.rdtSwitch,
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev,
.rdtPicker .rdtTimeToggle {
  color: #9c27b0; }

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker thead tr:first-child th {
  cursor: pointer; }

.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee; }

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer; }

.rdtPicker button:hover {
  background-color: #eee; }

.rdtPicker thead button {
  width: 100%;
  height: 100%; }

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer; }

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee; }

.rdtCounters {
  display: inline-block; }

.rdtCounters > div {
  float: left;
  width: 40px;
  font-weight: inherit;
  margin: 3px;
  border-radius: 50%; }

.rdtCounters .rdtCounterSeparator {
  width: 0;
  border: 1px solid transparent; }

.rdtCounter {
  height: 100px; }

.rdtCounter {
  width: 40px; }
  .rdtCounter .rdtCount {
    padding: 7px;
    height: 40px;
    border: 1px solid transparent; }

.rdtCounters .rdtCounter:last-child .rdtCount {
  color: #9c27b0;
  border-radius: 50%;
  border: 1px solid #9c27b0; }

.rdtCounterSeparator {
  padding: 7px;
  line-height: 100px; }

.rdtCounter .rdtBtn {
  line-height: 40px;
  cursor: pointer;
  display: block;
  border-radius: 50%;
  color: #eee;
  -webkit-transition: all 60ms ease-in;
  -moz-transition: all 60ms ease-in;
  -o-transition: all 60ms ease-in;
  -ms-transition: all 60ms ease-in;
  transition: all 60ms ease-in;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

.rdtCounter .rdtBtn:hover {
  background: #eee;
  color: #797979; }

.rdtCounter .rdtCount {
  font-size: inherit;
  line-height: 25px; }

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px; }

.rdtMilli input {
  width: 100%;
  font-size: inherit;
  margin-top: 37px; }

.rdtMonths,
.rdtYears {
  padding-bottom: 10px; }
  .rdtMonths .rdtMonth,
  .rdtMonths .rdtYear,
  .rdtYears .rdtMonth,
  .rdtYears .rdtYear {
    display: inline-block;
    width: 56px;
    height: 56px;
    line-height: 56px;
    margin: 3px 3px;
    cursor: pointer;
    border-radius: 50%;
    text-align: center; }
    .rdtMonths .rdtMonth.rdtActive,
    .rdtMonths .rdtYear.rdtActive,
    .rdtYears .rdtMonth.rdtActive,
    .rdtYears .rdtYear.rdtActive {
      background-color: #9c27b0 !important;
      color: #fff; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }
  @media (min-width: 768px) {
    .slick-slider .slick-caption {
      display: block !important; } }
  .slick-slider .slick-caption {
    padding-bottom: 45px;
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    color: #ffffff;
    text-align: center;
    z-index: 3;
    display: none; }
  .slick-slider .slick-slide > div:first-child {
    position: relative; }
  .slick-slider .slick-icons {
    position: relative;
    top: 5px; }
  .slick-slider .slick-image {
    width: 100% !important;
    display: inline-flex !important; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

button.slick-arrow.slick-prev,
button.slick-arrow.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  height: 100%;
  padding: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
  color: transparent;
  outline: none;
  background: transparent;
  width: 15%;
  z-index: 2;
  opacity: 0.5; }

.slick-prev {
  left: 0; }
  .slick-prev::before {
    content: "\f053";
    font-weight: 600;
    font-family: Font Awesome\ 5 Free;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    color: white;
    font-size: 30px;
    width: 100%; }

.slick-next {
  right: 0; }
  .slick-next::before {
    content: "\f054";
    font-weight: 600;
    font-family: Font Awesome\ 5 Free;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    color: #FFF;
    font-size: 30px;
    width: 100%; }

.slick-list {
  z-index: 1; }

.slick-dots {
  margin-top: 0;
  margin-bottom: 1rem;
  position: absolute;
  bottom: 5px;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
  z-index: 3; }

.slick-dots li, .slick-dots li button {
  width: 20px;
  height: 20px;
  cursor: pointer; }

.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  padding: 5px;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }
  .slick-dots li button::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    content: "\2022";
    text-align: center;
    opacity: 1;
    background-color: #FFF;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    transition: all 300ms linear; }

.slick-dots li.slick-active button:before {
  width: 15px;
  height: 15px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  top: -3px; }

.modal-container {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.custom-search-button {
  width: 100%;
  padding: 16px;
  margin-top: 16px; }

.-container {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  position: absolute;
  top: -42px; }

.featured-section {
  margin-top: 80px; }

.search-textfield {
  margin: 20px; }

.dashboard-small-box {
  margin-top: 20px;
  padding: 20px; }

.bg-green {
  background-color: #26a69a !important; }

.bg-yellow {
  background-color: #ffc107 !important; }

.bg-blue {
  background-color: #3f51b5 !important; }

.bg-cyan {
  background-color: #00bcd4 !important; }

.bg-pink {
  background-color: #b86eb6 !important; }

.bg-sky {
  background-color: #08d391 !important; }

.bg-lightgreen {
  background-color: #6fb23c !important; }

.bg-orange {
  background-color: #e77634 !important; }

.logo-user-dashboard {
  margin-top: 8px;
  margin-left: 52px;
  margin-bottom: -7px; }

.profile-pic {
  margin: 20px 10px 10px 20px;
  border-radius: 100px; }

.loader-center {
  position: absolute;
  height: 100vh;
  width: 100vh;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.category-image {
  max-width: 100%;
  height: auto;
  border-radius: 0 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 100%; }

.provider-company-detail {
  margin-top: -244px;
  margin-bottom: 20px;
  top: -15%;
  right: 20%; }

.display-provider-image {
  align-items: center;
  text-align: center;
  margin-top: 60px; }

.display-provider-content {
  text-align: center;
  margin: 30px 15px 0 15px;
  color: #ffffff; }

.display-provider-content .provider-basic-detail-ul {
  list-style: none;
  text-align: left;
  font-size: 17px; }

.display-provider-content .provider-basic-detail-ul li {
  padding: 6px; }

.display-provider-content .custom-book-now {
  width: 100%;
  height: 50px;
  margin-bottom: 35px; }

.provider-bio {
  padding-top: 20px;
  padding-bottom: 30px; }

.social-share {
  display: flex;
  flex-direction: column;
  padding: 70px; }

.social-share .social-share__container {
  padding: 10px; }

.category-title {
  color: #3c4858;
  margin: 1.75rem 0 0.875rem;
  margin-top: 40px;
  margin-bottom: 0px !important;
  min-height: 32px;
  font-weight: 700;
  font-family: 'Roboto Slab', 'Times New Roman', serif;
  margin-bottom: 1rem;
  text-decoration: none; }

.category-title-mobile {
  color: #3c4858;
  margin-top: 20px;
  margin-bottom: 0px !important;
  font-size: 20px !important;
  font-weight: 700;
  font-family: 'Roboto Slab', 'Times New Roman', serif;
  text-decoration: none; }

.category-title1 {
  color: #3c4858;
  margin: 1.75rem 0 0.875rem;
  margin-top: 0px;
  min-height: 32px;
  font-weight: 700;
  font-family: 'Roboto Slab', 'Times New Roman', serif;
  margin-bottom: 1rem;
  text-decoration: none; }

.category-heading h5 {
  list-style: none;
  color: #3c4858;
  font-size: 20px; }

.service-main-box {
  margin-top: 30px; }

.service-main-container {
  margin-bottom: 20px;
  margin-top: 50px; }

.dialog-form {
  display: flex;
  flex-direction: column; }

.dialog-header {
  align-self: center;
  width: 100%; }

.dialog-heading {
  color: #ffffff; }

.dialog-parent {
  overflow: visible; }

.login-form-textfield {
  margin-bottom: 30px;
  margin-top: 30px; }

.logintxt {
  justify-content: left !important; }

.verification-background {
  height: 1140px; }

.social-button {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 3px;
  padding: 7px;
  font-size: 17px;
  width: 90%;
  margin-bottom: 10px;
  margin-left: 44px;
  cursor: pointer; }

.social-button-dark {
  border-radius: 3px;
  padding: 7px;
  font-size: 17px;
  width: 40%;
  margin-bottom: 10px;
  margin-left: 44px;
  cursor: pointer; }

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: 'Work Sans', sans-serif !important; }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #323a45; }

.pr-65 {
  padding-right: 65px !important; }

.mr-10 {
  margin-right: 10px !important; }

.mr-20 {
  margin-right: 20px !important; }

.mb-5 {
  margin-bottom: 5px !important; }

.ml-none {
  margin-left: unset; }

.profile-image-width {
  max-width: 16.333333%; }

.flex-end {
  display: flex;
  justify-content: flex-end; }

::-moz-selection {
  color: #fff;
  background: #f36; }

::selection {
  color: #fff;
  background: #f36; }

h2 {
  font-size: 40px !important;
  line-height: 1.222em !important;
  font-weight: 400 !important;
  color: #323a45;
  margin-bottom: 5px; }

p {
  font-size: 16px;
  line-height: 1.875em;
  letter-spacing: 0;
  color: #7e8890;
  font-weight: 400; }

body {
  font-size: 16px;
  line-height: 1.875em;
  letter-spacing: 0;
  color: #7e8890;
  overflow-x: hidden !important;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  background-color: transparent; }

.category-inner {
  padding: 0;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #e9ebec;
  -webkit-transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
  -moz-transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
  position: relative; }
  .category-inner:hover .category-image-inner {
    opacity: 0; }

.category-image-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background-color: rgba(50, 58, 69, 0.6);
  z-index: 10;
  -webkit-transition: opacity 0.2s ease-out;
  -moz-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out; }
  .category-image-inner h5 {
    color: #fff;
    font-weight: bold;
    font-size: 20px; }

.overlay-banner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-items: center;
  text-align: center;
  flex-wrap: wrap;
  height: 100%;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4); }
  .overlay-banner h1 {
    color: #fff;
    font-size: 70px;
    line-height: 80px;
    font-weight: 400;
    margin-bottom: 20px; }
  .overlay-banner h5 {
    color: #fff;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400; }

.search-container {
  background-color: #ffffff;
  border: 0;
  position: relative;
  padding: 0 !important;
  max-width: 100% !important; }
  .search-container button {
    height: 66px;
    border-radius: 0;
    padding: 12px 22px;
    border: 0;
    margin-top: 0 !important;
    letter-spacing: 0.5em;
    box-shadow: none; }
  .search-container .search-container-row {
    margin: 0 !important;
    width: auto; }
    .search-container .search-container-row .search-container-col {
      border: 1px solid #f8edf0;
      border-right: 0; }
    .search-container .search-container-row .input-search {
      padding: 0;
      padding-left: 0px; }
      .search-container .search-container-row .input-search:focus {
        background-color: transparent; }
    .search-container .search-container-row .search-textfield.searhvar-filter,
    .search-container .search-container-row .search-container-input-new,
    .search-container .search-container-row .search-container-input2 {
      background-image: url("../img/edit-document-button.png");
      background-repeat: no-repeat;
      padding-left: 43px !important;
      background-position: 3% 50%;
      background-size: 18px; }
    .search-container .search-container-row .input-search {
      background-image: url("../img/list.png");
      background-repeat: no-repeat;
      padding-left: 43px;
      background-position: 0% 50%;
      background-size: 18px;
      color: #8a959e;
      padding: 0px 42px;
      font-size: 18px;
      font-weight: 400;
      height: 64px;
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif; }
    .search-container .search-container-row .fillButton {
      position: relative;
      overflow: hidden;
      display: inline-block;
      width: 150px;
      height: 30px;
      border: 1px solid #35495e;
      text-align: center;
      box-sizing: border-box;
      color: #35495e;
      text-decoration: none;
      cursor: pointer;
      line-height: 28px;
      font-family: 'arial';
      border-radius: 4px; }
    .search-container .search-container-row .search-container-col .container-text-input {
      margin: 0; }
      .search-container .search-container-row .search-container-col .container-text-input div:after, .search-container .search-container-row .search-container-col .container-text-input div:before {
        display: none !important; }
    .search-container .search-container-row .search-container-col fieldset {
      padding-left: 0 !important; }
      .search-container .search-container-row .search-container-col fieldset input {
        padding-left: 0; }
    .search-container .search-container-row .search-container-col input {
      color: #8a959e;
      border-radius: 0;
      border: 0;
      padding: 0px 6px;
      font-size: 18px;
      font-weight: 400;
      font-style: normal !important;
      height: 64px;
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
      padding-left: 17px !important; }
      .search-container .search-container-row .search-container-col input::placeholder {
        color: #8a959e;
        opacity: 1; }
      .search-container .search-container-row .search-container-col input::-webkit-input-placeholder {
        color: #8a959e;
        opacity: 1; }
      .search-container .search-container-row .search-container-col input::-moz-placeholder {
        color: #8a959e;
        opacity: 1; }
      .search-container .search-container-row .search-container-col input:-ms-input-placeholder {
        color: #8a959e;
        opacity: 1; }
      .search-container .search-container-row .search-container-col input:-moz-placeholder {
        color: #8a959e;
        opacity: 1; }
    .search-container .search-container-row [class*='MuiList-root-'] em {
      font-style: normal; }
    .search-container .search-container-row .search-container-select {
      width: 100%;
      margin: 0; }
      .search-container .search-container-row .search-container-select .searhvar-filter:before, .search-container .search-container-row .search-container-select .searhvar-filter:after {
        display: none !important; }
    .search-container .search-container-row .searhvar-filter svg {
      right: 15px; }
  .search-container .search-textfield {
    margin-top: 0;
    margin-bottom: 0; }
  .search-container .MuiFormControl-root-974 {
    width: 100%; }
  .search-container .searhvar-filter {
    margin-top: 0;
    margin-bottom: 0;
    height: 64px;
    line-height: 64px;
    padding: 0px 15px;
    font-size: 18px;
    color: #8a959e;
    border-radius: 0;
    border: 0;
    font-size: 18px;
    font-weight: 400;
    font-style: normal !important; }
    .search-container .searhvar-filter:after {
      display: none; }
    .search-container .searhvar-filter em {
      font-style: normal !important; }
    .search-container .searhvar-filter fieldset {
      border-radius: 0;
      border: 0; }
    .search-container .searhvar-filter label {
      font-size: 18px;
      font-weight: 400;
      font-style: normal !important;
      height: 66px;
      color: #8a959e; }
    .search-container .searhvar-filter input {
      color: #8a959e;
      border-radius: 0;
      border: 0;
      font-size: 18px;
      font-weight: 400;
      font-style: normal !important;
      height: 66px; }
      .search-container .searhvar-filter input::-webkit-input-placeholder {
        color: #8a959e; }
      .search-container .searhvar-filter input::-moz-placeholder {
        color: #8a959e; }
      .search-container .searhvar-filter input:-ms-input-placeholder {
        color: #8a959e; }
      .search-container .searhvar-filter input:-moz-placeholder {
        color: #8a959e; }

.searchbutton {
  background-color: #e24f36;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 28px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  width: 100%;
  padding: 15px;
  margin-top: 11px;
  display: inline-block;
  position: relative; }

[class*='Connect-TopCategories--section-'] > div [class*='MuiGrid-container-'] {
  margin-top: 40px; }

[class*='MuiPaper-elevation1-'] {
  box-shadow: none !important;
  border: 1px solid #e9ebec;
  border-radius: 0; }
  [class*='MuiPaper-elevation1-'] .tags-color {
    padding: 2px 10px;
    margin-right: 5px;
    margin-left: 5px;
    font-size: 12px; }

.featured-provider-section .slick-slider {
  margin-top: 40px; }
  .featured-provider-section .slick-slider .featured-avtar {
    color: #fff;
    background-color: #323a45; }
  .featured-provider-section .slick-slider .featured-business-name {
    color: #000;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.71429em; }
  .featured-provider-section .slick-slider .featured-business-tagline {
    color: #8a8a8a;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 500; }
  .featured-provider-section .slick-slider .featured-p {
    font-size: 16px;
    line-height: 1.875em;
    letter-spacing: 0;
    color: #fff; }
    .featured-provider-section .slick-slider .featured-p b {
      color: #fff;
      background: #ff3366;
      font-weight: 400;
      font-size: 13px;
      padding: 2px 5px;
      border-radius: 50px; }

.featured-provider-section .provider-after {
  position: relative; }
  .featured-provider-section .provider-after:after {
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 1;
    background: linear-gradient(to top, rgba(35, 35, 37, 0.9) 0%, rgba(35, 35, 37, 0.45) 35%, rgba(22, 22, 23, 0) 60%, rgba(0, 0, 0, 0) 100%);
    background-color: rgba(35, 35, 37, 0.2);
    z-index: 10;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out; }

.featured-provider-section .card-address {
  color: #fff;
  margin-bottom: 0; }

.featured-provider-section .featurecard-section {
  align-self: flex-end; }

.featured-provider-section .provider-overlay {
  z-index: 11;
  color: #fff;
  background-color: #e3e3e3;
  min-height: 150px;
  font-size: 20px;
  font-weight: 500;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 15px;
  padding-left: 5px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out; }
  .featured-provider-section .provider-overlay svg {
    fill: rgba(0, 0, 0, 0.87); }
  .featured-provider-section .provider-overlay .cardprice-starting {
    width: 30%;
    right: 0;
    top: 0;
    color: white;
    position: absolute;
    text-align: center;
    padding: 8px;
    background-color: #e24f36;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px; }
  .featured-provider-section .provider-overlay .featurecard-header {
    padding: 0; }
  .featured-provider-section .provider-overlay .star-feature {
    padding: 0px 5px;
    margin-bottom: 0;
    color: #000;
    display: inline-block;
    font-size: 22px;
    font-weight: 500; }

.featured-provider-section .provider-card {
  position: relative;
  cursor: pointer; }

.featured-provider-section .provider-card:hover .provider-overlay {
  padding-bottom: 30px; }

.featured-provider-section .provider-card:hover .provider-after {
  position: relative; }
  .featured-provider-section .provider-card:hover .provider-after:after {
    background: linear-gradient(to top, rgba(35, 35, 37, 0.9) 0%, rgba(35, 35, 37, 0.45) 35%, rgba(22, 22, 23, 0) 60%, rgba(0, 0, 0, 0) 100%);
    background-color: rgba(35, 35, 37, 0.5);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }

button:focus {
  outline: 0 !important; }

.how-it-work-section {
  border-top: 1px solid #f8edf0;
  margin-bottom: 0;
  border-bottom: 1px solid #f8edf0;
  padding-left: 15px !important;
  padding-right: 15px !important; }
  .how-it-work-section .how-it-infoarea {
    margin: inherit;
    max-width: 550px;
    display: flex;
    text-align: left;
    margin-left: auto;
    justify-content: end;
    padding: 20px 0; }
    .how-it-work-section .how-it-infoarea p {
      font-size: 12px;
      color: #444443; }
    .how-it-work-section .how-it-infoarea .how-it-info-description {
      padding: 0px 10px;
      margin: 0;
      margin-left: 0;
      padding-left: 0; }
      .how-it-work-section .how-it-infoarea .how-it-info-description h4 {
        margin-top: 0;
        font-size: 27px;
        line-height: 1.222em;
        margin-bottom: 10px;
        font-weight: bold !important; }
  .how-it-work-section .how-it-works-row {
    margin: auto;
    position: relative;
    margin-top: 60px; }
  .how-it-work-section .how-child-2 {
    padding-left: 60px; }
  .how-it-work-section .how-it-right {
    text-align: right; }
    .how-it-work-section .how-it-right [class*='InfoArea-iconWrapper-'] {
      order: 2; }
    .how-it-work-section .how-it-right .how-it-infoarea {
      text-align: right;
      margin-right: auto;
      margin-left: inherit; }
  .how-it-work-section .step-name {
    margin-left: 5px;
    left: 0;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 70px;
    color: #c9c9c7;
    text-align: left; }

.how-it-works-img {
  width: 340px;
  height: 340px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 12px;
  -webkit-box-shadow: 7px 4px 25px 0px rgba(0, 0, 0, 0.4); }
  .how-it-works-img img {
    max-width: 100%;
    height: 100%;
    object-fit: cover; }

.provider-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  -webkit-box-shadow: 7px 4px 25px 0px rgba(0, 0, 0, 0.4); }
  .provider-image img {
    max-width: 100%;
    height: 100%;
    object-fit: cover; }

.how-margin-top {
  margin-top: -145px !important;
  position: relative; }

.how-margin-top3 {
  margin-top: -165px !important; }

.description-wrap {
  max-width: 600px;
  margin: auto; }

.service-main-container-new h4.head-name {
  font-size: 20px;
  font-weight: 500 !important;
  margin-bottom: 20px; }

.service-main-container-new [class*='MuiPaper-root-'] {
  margin-bottom: 20px; }
  .service-main-container-new [class*='MuiPaper-root-'] [class*='MuiPaper-elevation1-'] {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important; }
  .service-main-container-new [class*='MuiPaper-root-'] button {
    width: 100%;
    text-align: center; }
    .service-main-container-new [class*='MuiPaper-root-'] button img {
      width: 100%; }
  .service-main-container-new [class*='MuiPaper-root-'] [class*='MuiTypography-body1'] {
    font-size: 14px; }

footer[class*='Footer-footer-'] {
  border-top: 1px solid #f8edf0;
  background-color: #fff;
  padding: 30px 0; }
  footer[class*='Footer-footer-'] .footer-mini {
    padding: 15px 0;
    font-size: 14px; }

.footer-row p {
  font-size: 14px; }

.footer-row h4 {
  font-size: 20px;
  font-weight: 500 !important;
  text-align: left; }

.footer-row ul li {
  padding: 0; }
  .footer-row ul li a {
    padding: 0;
    font-size: 14px;
    color: #8a959e;
    padding-left: 20px;
    text-transform: initial; }
    .footer-row ul li a:after {
      display: inline-block;
      font-family: IonIcons;
      position: absolute;
      speak: none;
      text-rendering: auto;
      text-transform: none;
      vertical-align: middle;
      content: '\25CF';
      top: 50%;
      left: 0;
      width: 20px;
      color: inherit;
      font-size: 9px;
      line-height: inherit;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      transform: translateY(-50%);
      -webkit-transition: left 0.2s cubic-bezier(0.54, 0.49, 0, 1.75);
      -moz-transition: left 0.2s cubic-bezier(0.54, 0.49, 0, 1.75);
      transition: left 0.2s cubic-bezier(0.54, 0.49, 0, 1.75); }

header {
  padding-right: 0 !important; }
  header .navigation-right li {
    margin-left: 5px; }
  header .navigation-right .btn-login {
    border: 1px solid #fff;
    border-radius: 50px;
    padding: 8px 28px;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 300;
    line-height: normal;
    height: 35px !important;
    line-height: normal; }
    header .navigation-right .btn-login:hover, header .navigation-right .btn-login:focus, header .navigation-right .btn-login:active {
      background: linear-gradient(to right bottom, #fc6262, #e77d31);
      color: #fff;
      border-color: none; }
    header .navigation-right .btn-login svg {
      fill: #fff; }
    header .navigation-right .btn-login:before, header .navigation-right .btn-login:after {
      display: none; }
    header .navigation-right .btn-login [class*='MuiSelect-select-'] {
      padding-top: 0;
      padding-bottom: 0;
      border: 0; }
      header .navigation-right .btn-login [class*='MuiSelect-select-']:focus {
        background-color: transparent; }

.downloadLinks {
  display: flex; }
  .downloadLinks li {
    margin-left: 5px; }
  .downloadLinks .DownloadAppLinks {
    border: 1px solid #fff;
    border-radius: 50px;
    padding: 8px 28px;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 300;
    line-height: normal;
    height: 35px !important;
    line-height: normal; }
    .downloadLinks .DownloadAppLinks:hover, .downloadLinks .DownloadAppLinks:focus, .downloadLinks .DownloadAppLinks:active {
      background: linear-gradient(to right bottom, #fc6262, #e77d31);
      color: #fff;
      border-color: none; }
    .downloadLinks .DownloadAppLinks svg {
      fill: #fff; }
    .downloadLinks .DownloadAppLinks:before, .downloadLinks .DownloadAppLinks:after {
      display: none; }
    .downloadLinks .DownloadAppLinks [class*='MuiSelect-select-'] {
      padding-top: 0;
      padding-bottom: 0;
      border: 0; }
      .downloadLinks .DownloadAppLinks [class*='MuiSelect-select-']:focus {
        background-color: transparent; }

[class*='MuiPopover-paper-'][class*='MuiPaper-elevation8-'] {
  box-shadow: none !important;
  border: 1px solid #e9ebec;
  border-radius: 0 !important; }
  [class*='MuiPopover-paper-'][class*='MuiPaper-elevation8-'] [class*='MuiListItem-root-'][class*='MuiListItem-button-']:hover {
    background-color: transparent;
    color: #ffa3ba; }
  [class*='MuiPopover-paper-'][class*='MuiPaper-elevation8-'] [class*='MuiListItem-root-'][class*='MuiListItem-selected-'] {
    background-color: transparent;
    color: #ff3366; }
    [class*='MuiPopover-paper-'][class*='MuiPaper-elevation8-'] [class*='MuiListItem-root-'][class*='MuiListItem-selected-']:hover {
      background-color: transparent;
      color: #ff3366; }

.modal-pop-li em {
  font-style: normal !important; }

.modal-pop-li:hover {
  background-color: transparent;
  color: #ffa3ba; }

body {
  overflow-y: auto !important;
  padding-right: 0 !important; }

[class*='MuiPaper-rounded-'][class*='MuiDialog-paper-'] {
  border-radius: 0; }

.margin-unset {
  margin: unset !important; }

.tab-bottom-border {
  border-bottom: 1px solid #ef9b8d !important; }

.dialog-form-new {
  margin: auto; }
  .dialog-form-new .dialog-heading {
    color: #ffffff;
    /* margin-bottom: 20px; */
    font-size: 18px;
    text-align: left;
    margin: 18px 20px;
    font-weight: 500; }
  .dialog-form-new .dialog-body {
    text-align: left; }
  .dialog-form-new .dialog-header {
    width: 100%;
    margin: 0;
    padding-bottom: 10px; }
  .dialog-form-new .logintxt {
    justify-content: left !important; }
  .dialog-form-new .social-button {
    width: 100%;
    margin-left: 0;
    padding: 0;
    font-size: 12px;
    background-color: transparent;
    border: 0; }
    .dialog-form-new .social-button img {
      max-width: 100%; }
  .dialog-form-new .social-button-dark {
    border-radius: 0;
    padding: 0;
    font-size: 0;
    width: 100%;
    margin-bottom: 10px;
    margin-left: 0;
    cursor: pointer;
    background: transparent;
    border: 0; }
    .dialog-form-new .social-button-dark img {
      max-width: 100%; }
  .dialog-form-new .login-form-textfield div:before, .dialog-form-new .login-form-textfield div:after {
    display: none; }
  .dialog-form-new .login-form-textfield input {
    height: auto;
    position: relative;
    width: 100%;
    margin-top: 10px;
    padding: 15px 15px 15px 30px;
    font-family: inherit;
    font-size: 14px;
    line-height: 18px;
    font-weight: inherit;
    color: #7e8890;
    background-color: transparent;
    border: 1px solid #e9ebec;
    outline: 0;
    -webkit-appearance: none;
    box-sizing: border-box;
    cursor: auto; }
    .dialog-form-new .login-form-textfield input:focus {
      border-color: #e24f36; }
  .dialog-form-new .login-form-textfield p {
    font-size: 12px !important;
    margin-top: 0px !important; }
  .dialog-form-new .login-form-textinput div:before, .dialog-form-new .login-form-textinput div:after {
    display: none; }
  .dialog-form-new .login-form-textinput input {
    height: auto;
    position: relative;
    width: 100%;
    margin-top: 10px;
    padding: 15px 15px 15px 30px;
    font-family: inherit;
    font-size: 14px;
    line-height: 18px;
    font-weight: inherit;
    color: black;
    background-color: #f6f9ff;
    border: 1px solid #f6f9ff;
    outline: 0;
    -webkit-appearance: none;
    box-sizing: border-box;
    cursor: auto; }
    .dialog-form-new .login-form-textinput input:focus {
      border-color: #e24f36; }
  .dialog-form-new .login-form-textinput textarea {
    height: auto;
    width: 100%;
    margin-top: 10px;
    padding: 15px 15px 15px 30px;
    font-family: inherit;
    font-size: 14px;
    line-height: 18px;
    font-weight: inherit;
    color: black;
    background-color: #f6f9ff;
    border: 1px solid #f6f9ff;
    outline: 0;
    -webkit-appearance: none;
    box-sizing: border-box;
    cursor: auto; }
    .dialog-form-new .login-form-textinput textarea:focus {
      border-color: #e24f36; }
  .dialog-form-new .login-form-textinput button {
    height: auto;
    position: relative;
    width: 100%;
    margin-top: 10px;
    padding: 15px 15px 15px 30px;
    font-family: inherit;
    font-size: 14px;
    line-height: 18px;
    font-weight: inherit;
    color: #000000;
    background-color: #f6f9ff;
    border: 1px solid #f6f9ff;
    outline: 0;
    -webkit-appearance: none;
    box-sizing: border-box;
    cursor: auto; }
    .dialog-form-new .login-form-textinput button:focus {
      border-color: #e24f36; }
  .dialog-form-new .login-form-textinput p {
    font-size: 12px !important;
    margin-top: 0px !important; }
  .dialog-form-new .log-btn button {
    max-width: 100%;
    border-radius: 50px;
    padding: 8px 35px;
    font-size: 16px;
    box-shadow: none;
    font-weight: 500; }

.sign-up-form {
  max-width: 500px;
  margin-bottom: 40px;
  margin: auto;
  margin-top: 40px;
  border: 1px solid #f8edf0; }

.flex-direction-row {
  display: flex !important;
  flex-direction: row !important; }

.flex-direction-column {
  display: flex !important;
  flex-direction: column !important; }

.flex-justify-spacebetween {
  display: flex !important;
  justify-content: space-between !important; }

.flex-justify-center {
  display: flex !important;
  justify-content: center !important; }

.max-w1000 {
  max-width: 1000px !important; }

.pr-0 {
  padding-right: 0 !important; }

.pl-10 {
  padding-left: 10px !important; }

.pl-15 {
  padding-left: 15px !important; }

.pl-20 {
  padding-left: 20px !important; }

.pl-30 {
  padding-left: 30px !important; }

.ml-0 {
  margin-left: 0 !important; }

.ml-5 {
  margin-left: 5px !important; }

.ml-10 {
  margin-left: 10px !important; }

.ml-15 {
  margin-left: 15px !important; }

.ml-20 {
  margin-left: 20px !important; }

.ml-25 {
  margin-left: 25px !important; }

.ml-30 {
  margin-left: 30px !important; }

.ml-35 {
  margin-left: 35px !important; }

.ml-40 {
  margin-left: 40px !important; }

.pt-0 {
  padding-top: 0 !important; }

.p-10 {
  padding: 10px !important; }

.p-0 {
  padding: 0 !important; }

.pl-0 {
  padding-left: 0 !important; }

.m-0 {
  margin: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.font-w400 {
  font-weight: 400 !important; }

.font-w500 {
  font-weight: 500 !important; }

.font-w600 {
  font-weight: 600 !important; }

.font-w700 {
  font-weight: 700 !important; }

.font-20 {
  font-size: 20px !important; }

.font-18 {
  font-size: 18px !important; }

.font-16 {
  font-size: 16px !important; }

.font-14 {
  font-size: 14px !important; }

.font-13 {
  font-size: 13px !important; }

.font-12 {
  font-size: 12px !important; }

.font-10 {
  font-size: 10px !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-black-400 {
  color: #000000 !important;
  font-weight: 400 !important; }

.text-black-300 {
  color: #000000 !important;
  font-weight: 300 !important; }

.w-30 {
  width: 30% !important; }

.w-100 {
  width: 100% !important; }

.mt-0 {
  margin-top: 0px !important; }

.mt-5 {
  margin-top: 5px !important; }

.mt-7 {
  margin-top: 7px !important; }

.mt-10 {
  margin-top: 10px !important; }

.mt-15 {
  margin-top: 15px !important; }

.mt-20 {
  margin-top: 20px !important; }

.mt-25 {
  margin-top: 25px !important; }

.mt-30 {
  margin-top: 30px !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.mb-25 {
  margin-bottom: 25px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.pb-20 {
  padding-bottom: 20px !important; }

.pb-50 {
  padding-bottom: 50px !important; }

.form-control-select {
  background-color: #f6f9ff !important;
  border: 1px solid #f6f9ff !important;
  height: auto !important;
  box-shadow: none !important;
  padding: 15px 15px 15px 30px !important;
  border-radius: 0 !important;
  font-size: 14px; }

.form-control-select:focus {
  border-radius: 0 !important;
  border-color: #e24f36 !important; }

.border-shadow-none {
  box-shadow: none !important; }

.error-text {
  font-size: 12px;
  color: #e24f36; }

.profile-label {
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 30px; }

.profile-text-label {
  font-size: 12px !important;
  font-weight: 500 !important; }

.postion-relative {
  position: relative !important; }

.profile-header-label {
  font-size: 12px;
  color: #e24f36;
  font-weight: 600;
  padding-left: 30px;
  margin-top: 30px;
  margin-bottom: 20px; }

.flex-align-center {
  display: flex !important;
  align-items: center !important; }

.password-error {
  color: #e24f36;
  font-size: 14px; }

.password-no-error {
  color: green;
  font-size: 14px; }

.upload-image-container {
  background-color: #e24f36;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  width: 25% !important; }

.upload-image-container-portfolio {
  background-color: #e24f36;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  width: 35% !important; }

.upload-image-label {
  color: #2396f3;
  font-size: 14px;
  font-weight: 600;
  margin-left: 15px; }

.plus-icon {
  color: #e24f36;
  font-size: 20px;
  font-weight: bold;
  background-color: #ffffff;
  padding: 5px 15px;
  border-radius: 20px; }

.add-portfolio {
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  background-color: #e24f36;
  padding: 4px 10px;
  border-radius: 20px; }

.select-time-container {
  padding: 10px 10px 10px 30px;
  color: #000000;
  background-color: #f6f9ff;
  border: 1px solid #f6f9ff;
  outline: 0;
  -webkit-appearance: none;
  box-sizing: border-box;
  cursor: auto; }
  .select-time-container:focus {
    border-color: #e24f36; }
  .select-time-container:hover {
    border-color: #e24f36; }

.primary-color {
  color: #e24f36 !important; }

.text-left {
  text-align: left !important; }

.text-center {
  text-align: center !important; }

.margin-auto {
  margin: auto; }

.tab-count-active {
  color: white;
  font-weight: 500;
  background-color: #e24f36;
  height: 35px;
  width: 35px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center; }

.tab-count-inactive {
  color: white;
  font-weight: 500;
  background-color: #9e9e9e;
  height: 35px;
  width: 35px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center; }

.tab-text-active {
  color: #000000 !important;
  font-weight: 500 !important;
  text-transform: capitalize !important; }

.tab-text-inactive {
  color: #9e9e9e !important;
  font-weight: 500 !important;
  text-transform: capitalize !important; }

.download-app-section {
  text-align: left !important;
  background-color: #fdfdfd;
  padding-bottom: 0 !important; }
  .download-app-section .description-wrap {
    padding-left: 0;
    margin-left: 0; }

.download-section-img img {
  max-width: 100%; }

.download-app-ul {
  margin-top: 40px;
  padding-left: 0; }
  .download-app-ul li {
    list-style: none;
    padding-right: 10px; }
    .download-app-ul li img {
      max-width: 150px; }

footer.footer-main {
  padding-top: 60px;
  padding-bottom: 40px;
  background-color: #252b33;
  color: #fff; }
  footer.footer-main h4 {
    color: #fff; }
  footer.footer-main p {
    color: #fff; }

footer.footer-main-mobile {
  padding-top: 15px;
  padding-bottom: 10px;
  background-color: #252b33;
  color: #fff; }
  footer.footer-main-mobile h4 {
    color: #fff; }
  footer.footer-main-mobile p {
    color: #fff; }

.footer-mini {
  padding-top: 40px;
  font-size: 14px;
  color: #fff; }
  .footer-mini a {
    color: #fff; }
    .footer-mini a:hover {
      color: #ff3366; }

.footer-row ul li a {
  color: #fff; }

.footer-row ul li p {
  font-size: 12px;
  color: #fff; }

.footer-row ul li a:hover {
  color: #ff3366; }

.fontlink {
  color: #fff;
  font-size: 14px;
  font-weight: bold; }
  .fontlink:hover {
    color: #fff; }

.footer-main-menu {
  margin-top: 20px; }
  .footer-main-menu [class*='MuiTypography-subheading-'] {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Work Sans', sans-serif !important; }
  .footer-main-menu li span {
    color: #fff; }

.signup-page {
  background-color: #f5f5f5 !important;
  padding-bottom: 50px; }
  .signup-page .sign-up-form header {
    box-shadow: none;
    background-color: #fff; }
    .signup-page .sign-up-form header button:first-child {
      border-right: 1px solid #f8edf0; }

.homeslider-main div {
  height: 100%; }
  .homeslider-main div .height-auto {
    height: auto; }

.home-slider-main-mobile .slick-slide img {
  object-fit: contain !important; }

.homeslider-main .slick-slide img,
.provider-right-slider .slick-slide img,
.feature-main-slider .slick-slide img,
.detail-slider-main .slick-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.homeslider-main button.slick-arrow.slick-prev,
.homeslider-main button.slick-arrow.slick-next,
.provider-right-slider button.slick-arrow.slick-prev,
.provider-right-slider button.slick-arrow.slick-next,
.feature-main-slider button.slick-arrow.slick-prev,
.feature-main-slider button.slick-arrow.slick-next,
.detail-slider-main button.slick-arrow.slick-prev,
.detail-slider-main button.slick-arrow.slick-next {
  background: rgba(0, 0, 0, 0.09);
  width: 80px;
  z-index: 2;
  opacity: 1;
  height: 80px;
  border-radius: 100px;
  font-weight: 400; }
  .homeslider-main button.slick-arrow.slick-prev:before,
  .homeslider-main button.slick-arrow.slick-next:before,
  .provider-right-slider button.slick-arrow.slick-prev:before,
  .provider-right-slider button.slick-arrow.slick-next:before,
  .feature-main-slider button.slick-arrow.slick-prev:before,
  .feature-main-slider button.slick-arrow.slick-next:before,
  .detail-slider-main button.slick-arrow.slick-prev:before,
  .detail-slider-main button.slick-arrow.slick-next:before {
    font-size: 22px; }
  .homeslider-main button.slick-arrow.slick-prev:hover,
  .homeslider-main button.slick-arrow.slick-next:hover,
  .provider-right-slider button.slick-arrow.slick-prev:hover,
  .provider-right-slider button.slick-arrow.slick-next:hover,
  .feature-main-slider button.slick-arrow.slick-prev:hover,
  .feature-main-slider button.slick-arrow.slick-next:hover,
  .detail-slider-main button.slick-arrow.slick-prev:hover,
  .detail-slider-main button.slick-arrow.slick-next:hover {
    background: rgba(0, 0, 0, 0.2); }

.homeslider-main button.slick-arrow.slick-prev,
.provider-right-slider button.slick-arrow.slick-prev,
.feature-main-slider button.slick-arrow.slick-prev,
.detail-slider-main button.slick-arrow.slick-prev {
  left: 20px; }

.homeslider-main button.slick-arrow.slick-next,
.provider-right-slider button.slick-arrow.slick-next,
.feature-main-slider button.slick-arrow.slick-next,
.detail-slider-main button.slick-arrow.slick-next {
  right: 20px; }

.provider-right-slider button.slick-arrow.slick-prev,
.feature-main-slider button.slick-arrow.slick-prev,
.provider-right-slider button.slick-arrow.slick-next,
.feature-main-slider button.slick-arrow.slick-next {
  background: rgba(0, 0, 0, 0.4);
  width: 50px;
  height: 50px; }

.featured-provider-section,
.how-it-work-section,
.home-topcategory-section {
  padding: 70px 0; }

.provider-list-style li {
  border: 1px solid #f8edf0;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 0;
  display: flex; }
  .provider-list-style li .provider-list-img-1 {
    width: 300px;
    height: 300px;
    object-fit: cover; }
  .provider-list-style li h3 {
    font-weight: 600;
    margin-top: 0; }

.provider-list-style .price-list {
  display: flex; }
  .provider-list-style .price-list h6 {
    margin: 0;
    line-height: 27px;
    font-size: 20px;
    margin-left: 10px !important; }

.provider-text-right {
  border: 1px solid #f8edf0;
  border-bottom: 1px solid #f8edf0;
  margin-bottom: 30px;
  margin-top: 8px; }

.btn-primary-new {
  border-radius: 30px !important;
  box-shadow: none !important; }

.br-15 {
  border-radius: 15px !important; }

.dialog-form-new2 {
  margin: auto;
  margin-bottom: 30px; }
  .dialog-form-new2 .modal-list h4 {
    font-weight: 600;
    margin-top: 0; }

.provider-detail-page {
  margin-top: 20px !important;
  box-shadow: none !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  margin-left: auto !important;
  margin-right: auto !important; }

.display-provider-image {
  margin-top: 0;
  width: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto; }

.display-provider-name {
  padding: 0px 15px 10px;
  text-align: center; }
  .display-provider-name h4 {
    font-weight: 600;
    color: #fff; }
  .display-provider-name p {
    line-height: 20px;
    color: #8a959e; }

.provider-company-detail {
  right: 0;
  padding: 20px;
  background: #323a45 !important;
  text-align: left;
  color: #fff !important;
  padding: 49px 34px 45px;
  margin-top: -175px;
  margin-bottom: 40px; }

.display-provider-content {
  text-align: left;
  margin: 10px 0px 0 0px;
  color: #fff; }

.display-provider-content .provider-basic-detail-ul {
  list-style: none;
  text-align: left;
  font-size: 15px;
  padding-left: 0; }
  .display-provider-content .provider-basic-detail-ul li {
    padding-top: 5px;
    padding-bottom: 5px; }
  .display-provider-content .provider-basic-detail-ul svg {
    width: 15px;
    margin-right: 5px; }

.provider-bio {
  padding-top: 10px;
  padding-bottom: 10px; }
  .provider-bio ul {
    padding-left: 20px;
    margin-top: 20px; }
  .provider-bio h3 {
    font-size: 19px;
    line-height: 1.263em;
    font-weight: 500; }
  .provider-bio h5 {
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    line-height: 1.875em;
    letter-spacing: 0;
    color: #7e8890;
    font-weight: 400; }

.provider-address h4 {
  font-size: 19px;
  line-height: 1.263em;
  font-weight: 500;
  margin-bottom: 30px; }

.display-provider-content .custom-book-now {
  width: 100%;
  height: 50px;
  margin-bottom: 35px;
  font-size: 14px;
  border-radius: 0;
  padding: 13px 62px;
  letter-spacing: 0.03em;
  margin: 20px 0 22px; }

.detail-tabs-verticals h4 {
  font-size: 19px;
  line-height: 1.263em;
  font-weight: 500; }

.badge-new {
  display: inline-block;
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  background: #00b489;
  padding: 10px;
  border-radius: 8px;
  line-height: 24px;
  margin: 10px; }

.detail-slider-main .slick-slide img {
  display: block;
  width: 100%;
  object-fit: cover; }

.contact-page {
  background-color: #f5f5f5 !important;
  padding: 70px 0; }

.contact-page-mobile {
  background-color: #f5f5f5 !important;
  padding-top: 20px; }

img.bubble1 {
  height: auto;
  max-width: 100%;
  object-fit: inherit;
  position: absolute;
  left: 0px;
  z-index: -1;
  top: 15px; }

img.bubble4 {
  height: auto;
  max-width: 100%;
  -o-object-fit: inherit;
  object-fit: inherit;
  margin-left: 182px;
  z-index: 1;
  top: 15px;
  position: relative; }

.kep-login-facebook .fa {
  margin-right: none !important; }

.kep-login-facebook {
  font-family: Helvetica, sans-serif;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  width: 40px !important;
  padding: 5px 1px !important;
  border-radius: 60px !important;
  cursor: pointer;
  display: inline-block;
  transition: background-color 0.3s, border-color 0.3s;
  background-color: #4c69ba; }

.social-icon-circle {
  width: 36px;
  border-radius: 50%;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem; }

i.fa.fa-facebook {
  font-size: 18px; }

.kep-login-facebook {
  border: 1px solid transparent !important; }

.google-btn {
  color: #fff !important;
  border-radius: 60px !important;
  margin-right: none !important; }

.sign-up-form .dialog-form-new .dialog-header {
  margin: 0 -15px; }

.dialog-form .dialog-body .row {
  padding: 10px 0;
  border-bottom: 1px solid #f8edf0; }
  .dialog-form .dialog-body .row:last-child {
    border-bottom: 0; }

.modal-fixed-to [class*='MuiPopover-paper-'][class*='MuiPaper-elevation8-'] {
  top: 60px !important; }

.card-title-text {
  color: #333; }

.card-spacing {
  padding: 1rem !important;
  margin-bottom: 1rem; }

.text-box-spacing {
  margin: 15px !important;
  max-width: 91% !important; }

.w-95 {
  width: 95% !important; }

.m-15 {
  margin: 15px !important; }

.service-main-collapse {
  background-color: #fff;
  box-shadow: 1px 1px 5px #e0e0e0;
  margin: 0 0px 15px 0px;
  min-height: 70px;
  box-shadow: 1px 1px 5px #e0e0e0;
  border-radius: 3px; }
  .service-main-collapse p {
    font-size: 16px; }
  .service-main-collapse .card-collapse-expan {
    border: 0;
    box-shadow: none;
    padding: 10px 10px; }
  .service-main-collapse .service-main-card {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 0;
    padding: 5px !important; }
    .service-main-collapse .service-main-card h5 {
      margin: 0 !important; }

.service-main-collapse > p {
  padding: 20px 20px;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.87); }

.category-main-lux {
  background-color: #f2f2f2 !important;
  padding-bottom: 50px; }

.detail-cardlux {
  -webkit-box-shadow: 0 1px 2px 0 rgba(84, 81, 81, 0.3);
  box-shadow: 0 1px 2px 0 rgba(84, 81, 81, 0.3);
  min-height: 346px;
  padding: 30px; }
  .detail-cardlux h3 {
    margin-bottom: 40px;
    font-size: 25px; }

.detail-cardlux-right {
  -webkit-box-shadow: 0 1px 2px 0 rgba(84, 81, 81, 0.3);
  box-shadow: 0 1px 2px 0 rgba(84, 81, 81, 0.3);
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 20px; }
  .detail-cardlux-right span {
    font-size: 14px;
    display: block; }

.detail-card-lx {
  margin-bottom: 20px; }
  .detail-card-lx i {
    margin-right: 10px; }

.categorymain-top {
  margin-top: -20px;
  padding-bottom: 50px; }
  .categorymain-top .btn-provider {
    margin: 50px auto auto !important;
    width: 180px !important;
    padding: 15px !important;
    display: block !important; }

.service-main-collapse {
  background-color: #fff;
  box-shadow: 1px 1px 5px #e0e0e0;
  margin: 0 0px 15px 0px;
  min-height: 70px;
  box-shadow: 1px 1px 5px #e0e0e0;
  border-radius: 3px; }
  .service-main-collapse p {
    font-size: 16px; }
  .service-main-collapse .card-collapse-expan {
    border: 0;
    box-shadow: none;
    padding: 10px 10px; }
  .service-main-collapse .service-main-card {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 0;
    padding: 5px !important; }
    .service-main-collapse .service-main-card h5 {
      margin: 0 !important; }

.order-large-btn {
  width: 250px;
  height: 50px;
  font-size: 18px; }

.detail-tabs-list-upper {
  padding-left: 0 !important; }

.provider-bio .detail-tabs-list {
  list-style-type: none;
  padding-left: 0 !important;
  padding-right: 0; }
  .provider-bio .detail-tabs-list li {
    border: 1px solid #f8edf0;
    padding: 0px;
    margin-bottom: 20px;
    display: flex;
    align-items: center; }
    .provider-bio .detail-tabs-list li .detail-book-right-padding {
      padding-right: 0; }
  .provider-bio .detail-tabs-list .detail-book-buttons {
    position: relative;
    padding: 20px;
    transform: inherit;
    top: 0; }
    .provider-bio .detail-tabs-list .detail-book-buttons button {
      width: 140px; }

.dialog-form .dialog-body .dialog-header {
  width: 100%; }

header .navigation-right .btn-profile {
  height: auto !important;
  padding: 0;
  margin-right: 5px; }

header .navigation-right .btn-login.btn-dash-cart {
  padding: 8px 15px; }

header .navigation-right .btn-lang {
  border: 0; }
  header .navigation-right .btn-lang:before, header .navigation-right .btn-lang:after {
    display: none; }

.modal-table td {
  padding: 8px; }

.btn-dash-cart + span {
  top: -2px;
  right: -2px;
  background-color: #fff;
  line-height: 22px;
  color: #000; }

.margin-left-right {
  margin-left: auto; }

.text-rightbook {
  text-align: right; }

.cart-table tr td,
.cart-table tr th {
  padding: 4px; }

.cart-paper {
  border: 1px solid #f8edf0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  padding: 20px !important;
  margin-bottom: 20px;
  text-align: left; }

.btn-save {
  display: flex;
  align-items: center; }
  .btn-save button {
    color: #fff;
    padding: 0 15px;
    background-color: transparent !important; }

.sign-up-form-2 {
  background-color: #fff;
  padding: 20px; }

html[lang='ar'] {
  direction: rtl;
  text-align: right; }
  html[lang='ar'] header ul li {
    float: right; }
  html[lang='ar'] .search-container .search-container-row [class*='MuiSelect-select-'] {
    text-align: right;
    padding-right: 35px;
    padding-left: 0;
    background-position: 100% 50%; }
  html[lang='ar'] .search-container .search-container-row .searhvar-filter svg {
    right: inherit;
    left: 15px; }
  html[lang='ar'] .search-container .search-container-row .search-textfield.searhvar-filter,
  html[lang='ar'] .search-container
.search-container-row
[class*='Connect-ServiceAutoSuggest--root'] {
    padding-left: 0;
    background-position: 97% 50%;
    background-size: 18px;
    padding-right: 35px; }
  html[lang='ar'] .featured-provider-section .provider-overlay {
    direction: rtl;
    text-align: right; }
  html[lang='ar'] .how-it-work-section .how-child-2 {
    padding-right: 60px;
    margin-right: auto;
    margin-left: 0 !important; }
  html[lang='ar'] .how-it-work-section .how-it-infoarea {
    text-align: right; }
  html[lang='ar'] .how-it-work-section .step-name {
    text-align: right;
    padding-right: 15px; }
  html[lang='ar'] .download-app-section {
    text-align: right !important; }
  html[lang='ar'] .download-app-section .description-wrap {
    margin-left: auto;
    direction: rtl;
    margin-right: 0; }
  html[lang='ar'] .footer-row h4 {
    text-align: right; }
  html[lang='ar'] .footer-main-menu {
    margin-top: 20px;
    text-align: right; }
  html[lang='ar'] footer ul li {
    text-align: right; }
  html[lang='ar'] .signup-page .sign-up-form header button:first-child {
    border-left: 1px solid #f8edf0;
    border-right: 0; }
  html[lang='ar'] .find-ul li {
    text-align: right; }
  html[lang='ar'] .find-ul .find-us {
    padding-right: 25px;
    padding-left: 0; }
  html[lang='ar'] .contact-page h3 {
    text-align: right; }
  html[lang='ar'] .contact-page .dialog-form-new2 {
    margin-left: auto;
    margin-right: 0; }
  html[lang='ar'] .provider-list-style li {
    text-align: right; }
    html[lang='ar'] .provider-list-style li h3 {
      text-align: right; }
    html[lang='ar'] .provider-list-style li h6 {
      text-align: right; }
  html[lang='ar'] .provider-list-style .price-list h6 {
    margin-right: 10px !important;
    margin-left: 0 !important; }
  html[lang='ar'] .provider-text-right {
    text-align: right; }
  html[lang='ar'] .provider-bio {
    text-align: right; }
    html[lang='ar'] .provider-bio h3,
    html[lang='ar'] .provider-bio p {
      text-align: right; }
  html[lang='ar'] .provider-bio ul {
    padding-right: 20px; }
  html[lang='ar'] .provider-bio ul.detail-tabs-list-upper {
    padding-right: 0; }
  html[lang='ar'] .display-provider-content .provider-basic-detail-ul {
    text-align: right;
    padding-right: 0; }
  html[lang='ar'] .display-provider-content .provider-basic-detail-ul svg {
    margin-right: 3px;
    margin-left: 5px; }
  html[lang='ar'] .category-title {
    text-align: right;
    margin-top: 55px; }
  html[lang='ar'] .service-main-box h3 {
    text-align: right; }
  html[lang='ar'] .service-main-collapse p {
    text-align: right; }
  html[lang='ar'] .service-main-box .element {
    padding-right: 0 !important;
    padding-left: 40px; }
  html[lang='ar'] .dialog-form .dialog-body label {
    transform-origin: top right;
    right: 0;
    left: inherit; }
  html[lang='ar'] .SideNav-arab {
    left: auto;
    right: 0; }
    html[lang='ar'] .SideNav-arab .arab-link-right {
      text-align: right; }
  html[lang='ar'] .mui-fixed {
    left: 0;
    margin-left: 0;
    right: auto; }
  html[lang='ar'] .margin-left-right {
    margin-right: auto;
    margin-left: 0; }
  html[lang='ar'] .arab-table table .text-left {
    text-align: right !important; }
  html[lang='ar'] .arab-table table .text-right {
    text-align: left !important; }
  html[lang='ar'] .main-div {
    text-align: right; }
  html[lang='ar'] #address-create label,
  html[lang='ar'] #customer-create label {
    text-align: right;
    right: 0;
    left: auto;
    transform-origin: top right; }
  html[lang='ar'] .arab-right-table {
    text-align: right; }
    html[lang='ar'] .arab-right-table td,
    html[lang='ar'] .arab-right-table td {
      text-align: right; }
  html[lang='ar'] .modal-table td {
    text-align: right; }
  html[lang='ar'] .dialog-form-new .dialog-body {
    text-align: right; }
    html[lang='ar'] .dialog-form-new .dialog-body p {
      text-align: right; }
  html[lang='ar'] .text-left {
    text-align: right !important; }
  html[lang='ar'] .dialog-form-new p {
    text-align: right; }
  html[lang='ar'] table {
    text-align: right; }
    html[lang='ar'] table td,
    html[lang='ar'] table th {
      text-align: right; }
  html[lang='ar'] .main-div table tr td,
  html[lang='ar'] .main-div table tr th {
    text-align: right; }
  html[lang='ar'] .arab-right {
    text-align: right; }
  html[lang='ar'] .arab-btn-right {
    left: 0;
    right: inherit; }
  html[lang='ar'] .text-rightbook {
    text-align: left; }
  html[lang='ar'] .modal-fixed-to
[class*='MuiPopover-paper-'][class*='MuiPaper-elevation8-'] {
    left: 0px !important; }
  html[lang='ar'] .favorite-table button[aria-label='Previous Page'] {
    transform: rotatey(180deg); }
  html[lang='ar'] .favorite-table button[aria-label='Next Page'] {
    transform: rotatey(-180deg); }
  html[lang='ar'] .modal-fixed-to ul li {
    text-align: right; }
  html[lang='ar'] .cart-paper {
    text-align: right; }
  html[lang='ar'] .cart-table tr td,
  html[lang='ar'] .cart-table tr th {
    text-align: right; }
    html[lang='ar'] .cart-table tr td:last-child,
    html[lang='ar'] .cart-table tr th:last-child {
      padding-right: 4px; }
  html[lang='ar'] .dialog-body label {
    text-align: right;
    left: auto;
    right: 0;
    width: 100%;
    transform-origin: top right; }
  html[lang='ar'] .cart-paper label {
    text-align: right;
    left: auto;
    right: 0;
    width: 100%;
    transform-origin: top right; }
  html[lang='ar'] .cart-paper button {
    margin-left: 5px; }
  html[lang='ar'] .footer-row ul li a {
    padding-left: 0px;
    padding-right: 20px; }
    html[lang='ar'] .footer-row ul li a:after {
      right: 0; }

.featuredPro {
  position: absolute;
  right: 7px;
  top: 7px;
  background-color: #64bc36;
  padding: 2px 10px;
  color: #fff;
  font-size: 11px; }

.provider-list-style .star-feature {
  background-color: #64bc36;
  padding: 0px 12px;
  color: #fff;
  border-radius: 20px;
  display: inline-block;
  font-size: 12px;
  margin-left: 5px;
  margin-top: 0;
  margin-bottom: 0; }

.provider-list-style li h3 {
  display: flex;
  align-content: center;
  align-items: center;
  align-self: center; }

.header-links-menu ul li span {
  font-size: 16px; }

.header-links-menu .navigation-right .btn-login {
  font-size: 16px; }

.provider-bio .display-provider-content {
  color: #000; }
  .provider-bio .display-provider-content .btn-save button {
    color: #fff; }

.save-later-btn {
  float: right; }
  .save-later-btn .btn-save button {
    color: #000; }
    .save-later-btn .btn-save button span {
      font-size: 12px; }

.list-icons {
  display: flex;
  align-items: center; }
  .list-icons li {
    list-style: none;
    padding: 0 10px; }
    .list-icons li i.fa-heart {
      color: #000;
      font-size: 18px; }

.chat-user {
  color: #000; }

.chat-user span.name {
  font-size: 15px; }

.chat-item .bubble {
  padding: 8px 12px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
  -o-box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
  border-radius: 20px 20px 20px 0px;
  position: relative;
  margin-left: 16px;
  max-width: 600px; }

.chat-item {
  padding: 16px; }

.chat-item.flex-row-reverse .bubble {
  margin-right: 16px;
  margin-left: 0;
  background-color: #e4e7f6;
  border-radius: 20px 20px 0 20px; }

.chat-box-main {
  color: #000; }

.inbox-heading {
  padding-left: 15px;
  color: #fff;
  font-weight: bold; }

.chat-tabs-header {
  margin-bottom: 15px; }

.chat-user-row {
  cursor: pointer; }

.chat-user-item {
  border-top: 1px solid #ddd; }

.he-20 {
  height: 18px;
  margin-left: 8px; }

.date-pick table tbody tr td {
  color: #000000 !important; }

.badge-noti span {
  width: auto;
  padding: 5px 7px;
  border-radius: 50px;
  top: 0px; }

.SideNav-arab .side-nav-link {
  padding: 0; }

.recharts-wrapper {
  width: 100% !important; }

.chart-responsive svg {
  width: 100% !important; }

.credit-cards {
  max-width: 100%;
  padding: 6px;
  margin-top: 20px; }

.dashboard-tables td,
.dashboard-tables th {
  padding: 4px 24px 4px 24px; }

.tablePaginationSelectIcon {
  top: 7px !important;
  left: 25px !important; }

.dashboard-table-pagination button {
  padding: 2px; }

.add-check-padding {
  padding-left: 0; }

.tableWrapper-table {
  overflow-x: auto; }

.rccs {
  max-width: 290px;
  width: 100%;
  margin-bottom: 15px; }

.rccs__card {
  width: 100%; }

.autosuggest-places {
  color: #000;
  cursor: pointer; }

.geosuggest__input {
  margin-top: 15px;
  padding: 10px;
  width: 400px; }

.search-location input {
  margin-top: 15px;
  padding: 10px;
  width: 300px !important; }

.width-300 {
  width: 300px !important; }

.height-50 {
  height: 50px !important; }

.overflow-hidden {
  overflow: hidden !important; }

.height-70vh {
  height: 70vh !important; }

.ellipsis {
  text-overflow: ellipsis;
  white-space: wrap;
  overflow: hidden; }

.MuiExpansionPanelSummary-content-946 > :last-child {
  padding-right: 0px; }

div.scrollmenu {
  background-color: #ffffff;
  overflow: auto;
  white-space: nowrap; }

div.scrollmenu span {
  display: inline-block;
  color: #888;
  text-align: center;
  padding: 14px;
  text-decoration: none; }

.find-ul {
  padding: 0;
  list-style: none;
  margin-top: 30px; }
  .find-ul li {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    line-height: 24px; }
  .find-ul .find-us {
    padding-left: 25px; }
    .find-ul .find-us strong {
      display: block;
      font-weight: 400;
      color: #000; }

.contact-page .dialog-form-new2 {
  margin-left: 0;
  max-width: 700px; }

@media (max-width: 1250px) {
  .how-it-works-img {
    width: 300px;
    height: 300px; }
  img.bubble4 {
    max-width: 250px;
    top: 15px; } }

@media (max-width: 1199px) {
  .how-it-works-img {
    width: 260px;
    height: 260px; }
  .how-margin-top {
    margin-top: -125px !important; }
  .how-it-work-section .how-it-infoarea .how-it-info-description h4 {
    font-size: 24px; }
  .how-it-work-section .step-name {
    margin-left: 5px;
    font-size: 55px;
    line-height: inherit; }
  img.bubble2 {
    max-width: 130px; }
  img.bubble4 {
    max-width: 250px;
    top: 15px; } }

@media (max-width: 991px) {
  h2 {
    font-size: 30px !important; }
  .download-app-ul li img {
    max-width: 120px; }
  .overlay-banner h1 {
    font-size: 50px;
    line-height: 60px; }
  .search-container .search-container-row .search-container-col {
    border: 1px solid #f8edf0;
    border-bottom: 0; }
  .category-inner {
    margin-bottom: 20px; }
  .how-it-works-img {
    width: 210px;
    height: 210px;
    -webkit-box-shadow: 14px 14px 19px 0px rgba(0, 0, 0, 0.4);
    box-shadow: 14px 14px 19px 0px rgba(0, 0, 0, 0.4); }
  img.bubble4 {
    max-width: 130px;
    top: 15px; }
  img.bubble2 {
    max-width: 90px; }
  .how-margin-top {
    margin-top: -85px !important; }
  .how-it-work-section .how-it-infoarea .how-it-info-description h4 {
    font-size: 21px; }
  img.bubble1 {
    max-width: 50px; }
  img.bubble3 {
    max-width: 50px; } }

@media (max-width: 959.95px) {
  .navigation-right li:after, .navigation-right li:before,
  .navigation-left li:after,
  .navigation-left li:before {
    display: none; }
  .navigation-right li button,
  .navigation-right li .btn-login,
  .navigation-left li button,
  .navigation-left li .btn-login {
    font-size: 16px;
    color: #323a45 !important;
    line-height: 1.26em;
    font-weight: 400;
    padding: 8px 0 8px 15px;
    text-transform: initial;
    width: 100%;
    margin: 0; }
    .navigation-right li button:after, .navigation-right li button:before,
    .navigation-right li .btn-login:after,
    .navigation-right li .btn-login:before,
    .navigation-left li button:after,
    .navigation-left li button:before,
    .navigation-left li .btn-login:after,
    .navigation-left li .btn-login:before {
      display: none; }
  .profile-tabs-dash div {
    display: inherit; }
  .profile-tabs-dash div {
    white-space: initial; }
  .profile-tabs-dash button {
    font-size: 11px;
    width: 50%; }
  .text-center.arab-table {
    overflow-x: auto; }
  .provider-company-detail {
    margin-top: 30px; }
  .how-child-1 {
    padding-right: 60px; }
  .how-it-work-section .how-it-infoarea {
    padding: 8px 0; }
  .how-margin-top {
    margin-top: 20px !important; }
  .how-it-work-section .how-child-2 {
    padding-left: 15px; } }

@media (max-width: 767px) {
  .how-it-work-section .how-it-works-row {
    text-align: center; }
  .how-it-work-section .how-it-works-row:after {
    display: none; }
  .how-it-work-section .how-it-infoarea .how-it-info-description {
    padding: 0;
    margin: 0;
    margin-left: 0;
    padding-left: 0;
    text-align: left; }
  .footer-mini {
    padding-top: 0;
    font-size: 12px; }
  .overlay-banner h1 {
    font-size: 30px;
    line-height: 30px; }
  .homeslider-main button.slick-arrow.slick-prev,
  .homeslider-main button.slick-arrow.slick-next,
  .detail-slider-main button.slick-arrow.slick-prev,
  .detail-slider-main button.slick-arrow.slick-next {
    width: 40px;
    height: 40px; }
  .homeslider-main button.slick-arrow.slick-prev,
  .detail-slider-main button.slick-arrow.slick-prev {
    left: 10px; }
  .homeslider-main button.slick-arrow.slick-next,
  .detail-slider-main button.slick-arrow.slick-next {
    right: 10px; }
  .overlay-banner h5 {
    font-size: 15px;
    line-height: 22px; }
  .search-container .search-container-row .search-container-col input {
    font-size: 15px;
    height: 50px; }
  .home-logo img {
    max-width: 130px;
    width: 100%;
    height: auto; }
  .search-container .searhvar-filter {
    height: 50px;
    line-height: 50px;
    font-size: 15px; }
  .search-container button {
    height: 52px;
    padding: 12px 22px;
    letter-spacing: 0.3em;
    font-size: 14px; }
  .how-it-work-section .how-it-works-row {
    margin: auto -15px; }
  header {
    padding-top: 0px !important; }
  .search-container .search-container-row .search-textfield.searhvar-filter,
  .search-container
.search-container-row
[class*='Connect-ServiceAutoSuggest--root'] {
    padding-left: 35px;
    background-position: 6% 50%;
    background-size: 16px; }
  .search-container .search-container-row [class*='MuiSelect-select-'] {
    background-size: 16px; }
  .featured-provider-section,
  .how-it-work-section,
  .home-topcategory-section {
    padding: 30px 0; }
  .download-app-section {
    padding-top: 30px !important; }
  .featured-provider-section .slick-slider .featured-avtar {
    width: 30px;
    height: 30px;
    font-size: 17px; }
  .featured-provider-section .slick-slider .featured-business-name {
    font-size: 16px; }
  .featured-provider-section .slick-slider .featured-business-tagline {
    font-size: 14px; }
  .category-image-inner h5 {
    font-size: 14px; }
  .provider-list-style li .provider-list-img-1 {
    width: 150px;
    height: 150px; }
  img.bubble1,
  img.bubble2,
  img.bubble3,
  img.bubble4 {
    display: none; }
  .how-it-works-img {
    width: 170px;
    height: 170px; }
  .how-it-work-section .step-name {
    font-size: 45px; }
  .how-it-work-section .how-it-infoarea .how-it-info-description h4 {
    font-size: 20px;
    margin-bottom: 0px; }
  .how-margin-top {
    margin-top: 30px !important; }
  .how-it-work-section .how-it-works-row {
    margin-top: 30px; }
  .navigation-right li:after, .navigation-right li:before {
    display: none; }
  .navigation-right li html[lang='ar'] .how-it-work-section .how-child-2 {
    padding-right: 15px; }
  .navigation-right li html[lang='ar'] .how-it-work-section .how-it-infoarea .how-it-info-description {
    text-align: right; }
  .dashboard-small-box h3,
  .dashboard-small-box h4 {
    margin: 0; }
  .main-div {
    overflow-x: visible;
    width: 100%; }
  .dashboard-booking-tabs div {
    display: block;
    padding: 5px 0; }
    .dashboard-booking-tabs div img {
      width: 130px !important;
      height: auto !important; }
  .dashboard-booking-tabs h3 {
    font-size: 18px; }
  .booking-detail-arrows {
    top: 0 !important;
    transform: translateY(0%) !important; }
    .booking-detail-arrows button {
      padding: 5px; }
  .dashboard-profile-bookhours {
    position: relative !important;
    top: 0 !important;
    padding-left: 10px; }
  .dashboard-bookhr-list {
    padding: 0 !important; }
    .dashboard-bookhr-list .dashboard-bookhr-text {
      padding: 0; }
    .dashboard-bookhr-list span {
      padding-left: 0;
      padding-right: 10px;
      padding-top: 0; }
  .dashboard-wallet-list {
    display: block !important; }
    .dashboard-wallet-list button {
      margin-top: 5px !important; }
  .chart-responsive .recharts-wrapper {
    height: 300px !important; }
  .chart-responsive svg {
    width: 100% !important;
    height: 300px !important; }
  .mobile-img-table {
    width: 130px !important;
    height: auto !important; }
  header .navigation-right .btn-login {
    border: 0;
    padding: 5px 2px; }
  header h6 {
    max-width: 48%; }
  .badge-noti span {
    padding: 2px 5px;
    font-size: 11px; } }

@media (max-width: 599px) {
  body,
  p {
    font-size: 12px;
    line-height: 1.375em; }
  .home-logo img {
    max-width: 150px;
    width: 100%;
    height: auto; }
  .how-it-work-section .how-it-infoarea .how-it-info-description h4 {
    font-size: 20px;
    margin-bottom: 15px; }
  .how-it-work-section .how-it-infoarea p {
    font-size: 14px;
    line-height: 22px; }
  h2 {
    font-size: 23px !important; }
  .featured-provider-section,
  .how-it-work-section,
  .home-topcategory-section {
    padding: 20px 0; }
  .download-app-section {
    padding-top: 20px !important; }
  .provider-list-style li {
    margin-bottom: 5px;
    display: block; }
  .provider-list-style li .provider-list-img-1 {
    width: 200px;
    height: 125px;
    margin-bottom: 10px; }
  .btn-primary-new {
    font-size: 13px !important; }
  h3 {
    font-size: 22px; }
  .how-it-works-img {
    width: 130px;
    height: 130px;
    -webkit-box-shadow: 7px 7px 10px 0px rgba(0, 0, 0, 0.4);
    box-shadow: 7px 7px 10px 0px rgba(0, 0, 0, 0.4); }
  .provider-bio .detail-tabs-list li {
    display: block;
    text-align: center; }
    .provider-bio .detail-tabs-list li .align-items-center {
      justify-content: center; }
    .provider-bio .detail-tabs-list li .provider-bio .detail-tabs-list .detail-book-buttons {
      padding-top: 0px; } }
